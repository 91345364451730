.footer {
    color: $footer-color;
    background-color: $footer-bg-color;

    hr {
        border-color: $footer-color;
    }

    a {
        color: $footer-color;

        &:hover {
            color: $footer-color;
            text-decoration: none;
        }
    }

    &__logo {
        display: block;
        margin-bottom: 1.5rem;
    }

    &__contact-link {
        display: block;
        margin-bottom: 1rem;
    }

    &__info {
        padding-left: 1rem;
    }

    &__title {
        color: white;
        margin-bottom: 1.5rem;
    }

    &__privacy-link {
        display: block;
        width: 12.5rem;
        background-color: $footer-privacy-link-bg-color;
        border-radius: 0.625rem;
        padding: 0.3125rem 0.625rem;
        margin-bottom: 0.625rem;
    }
}

.content {
    &--colored{
        background-color: $secondary-bg;
    }


    @include media-breakpoint-up(lg) {
        min-height: 500px;
    }

    &__text {
        @include media-breakpoint-up(lg) {
            width: 55%;
        }
    }

    &__img {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 40%;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}
.center-images{
    position: relative;

    img{
        width: 100%;
        display: block;
        margin: auto;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
}

.parameters {
    background-color: $parameters-bg-color;
    color: $parameters-color;
    h2{
        color: $parameters-color;
    }
    &__item{
        color: $parameters-color;
    }
}

.button {
    color: $button-color;
    background-color: $button-bg-color;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.25rem;
    padding: 0.3125rem 0.625rem;
    cursor: pointer;
    &:hover{
        text-decoration: none;
        color: $button-color;
    }
    &--download{
        font-size: 1.8rem;
        padding: 1.875rem;
    }
}

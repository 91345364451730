:root {
    --swiper-theme-color: #000;
}

.investors {
    padding: 3rem;
    @include media-breakpoint-up(xl) {
        padding: unset;
    }

    img {
        width: 100%;
        @include media-breakpoint-up(xl) {
            width: 40vw;
        }
    }

    &__text_box {

        @include media-breakpoint-up(xl) {
            /*padding: 3rem;*/
            padding: 6rem 3rem;
        }
        font-size: 1.5rem;
    }
}

.investment-card-icon {
    width: 100px;
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
}

.investment-card-1 {
    border: none;
    background: rgb(141, 172, 228);
    background: linear-gradient(0deg, rgba(141, 172, 228, 1) 0%, rgba(46, 103, 206, 1) 60%);

}

.investment-card-2 {
    border: none;
    background: rgb(162, 187, 231);
    background: linear-gradient(0deg, rgba(162, 187, 231, 1) 0%, rgba(86, 131, 214, 1) 60%);

}

.investment-card-3 {
    border: none;
    background: rgb(184, 203, 235);
    background: linear-gradient(0deg, rgba(184, 203, 235, 1) 0%, rgba(125, 159, 221, 1) 60%);

}

.investment-card-4 {
    border: none;
    background: rgb(249, 181, 134);
    background: linear-gradient(0deg, rgba(249, 181, 134, 1) 0%, rgba(243, 110, 19, 1) 60%);

}

.investment-card-5 {
    border: none;
    background: rgb(248, 193, 155);
    background: linear-gradient(0deg, rgba(248, 193, 155, 1) 0%, rgba(243, 137, 65, 1) 60%);

}

.investment-card-6 {
    border: none;
    background: rgb(247, 206, 177);
    background: linear-gradient(0deg, rgba(247, 206, 177, 1) 0%, rgba(243, 164, 109, 1) 60%);

}

.investment-swiper {
    .swiper-slide{
        padding-top: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        background-color: #EFA65E;
    }
}

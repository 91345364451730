.solar-parks {
    background-color: $secondary-bg;

    &__map {
        position: relative;
    }

    &__map-item {
        display: none;
        background-image: url("../assets/img/solar-park-layer.png");
        width: 382px;
        height: 633px;
        position: absolute;
        background-position: center;
        background-size: contain;
        z-index: 10;
        background-repeat: no-repeat;

        &.active {
            display: unset;
        }

        .solar-park-image {
            width: 50%;
            display: block;
            margin-left: 21%;
            margin-top: 35px;
        }


        ul {
            list-style-type: none;
            color: white;
            font-size: 14px;
            text-align: left;
            font-weight: bold;
            padding-left: 22px;
            padding-right: 10px;
            margin-bottom: 0;


        }

    }

    &__map-item-toggler {
        width: 35px;
        height: 35px;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        z-index: 9;
        background-color: white;
        border-radius: 50px;
        background-image: url("../assets/img/point.png");
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;

        @include media-breakpoint-up(lg) {
            width: 50px;
            height: 50px;
        }

    }

    .accordion {
        background: transparent;
        color: #394f65;
        cursor: pointer;
        padding: 18px;
        font-size: 20px;
        width: 80%;
        text-align: left;
        border: none;
        outline: none;
        font-weight: bold;
    }

    .zoom-button {
        background: transparent;
        color: #394f65;
        cursor: pointer;
        padding: 18px;
        font-size: 20px;
        width: 80%;
        text-align: left;
        border: none;
        outline: none;
        font-weight: bold;
    }

    .active, .accordion:hover, .accordion i {
        color: #ff9a3d;
    }

    .panel {
        padding: 0 18px;
        max-height: 0;
        overflow: hidden;
        width: 80%;
        transition: max-height 0.2s ease-out;
    }


}


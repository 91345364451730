.navbar {
    align-items: flex-end;
    box-shadow: unset;
    padding: 0 1rem;

    .navbar-top-row {
        position: absolute;
        width: 100%;
        right: 0;
        top: 0;
    }

    .navbar-brand {
        @include media-breakpoint-up(lg) {
            margin-bottom: 1.25rem;
            margin-top: 2rem;
        }


        img {
            height: 2rem;
            @include media-breakpoint-up(lg) {
                height: unset;
            }
        }
    }

    .navbar-nav > li {
        @include media-breakpoint-up(lg) {
            padding-left: 0.55rem;
            padding-right: 0.5rem;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 1.45rem;
            padding-right: 1.2rem;
        }

        .nav-link {
            @include media-breakpoint-up(lg) {
                padding-bottom: 1.6875rem;
            }
        }

        &.active {
            .nav-link {
                color: $navbar-link-active-color !important;
            }

        }

        .nav-link {
            text-transform: uppercase;
            color: $navbar-link-color !important;
            font-weight: bold;


            &:hover {
                color: $navbar-link-active-color !important;
            }
        }
    }

    .dropdown-menu {
        box-shadow: none;
        border: none;

        .dropdown-item {

            text-transform: uppercase;
            color: $navbar-link-color;
            font-weight: bold;
            @include media-breakpoint-up(lg) {
                font-weight: normal;
            }

            &:hover {
                background-color: unset;
            }
        }

        .dropdown-item.active, .dropdown-item:active {
            background-color: white;
        }
    }
}


.workers {
    font-size: 1.5rem;

    h5 {
        font-size: 1.5rem;
        color: $title-color;
        font-weight: bold;
    }

    p {
        font-size: 1rem;
        color: $title-color;
    }

    img {
        width: 250px;
        border: 1px solid $primary-color;
    }

    .read-more {
        font-size: 18px;
        border: none;
        background-color: transparent;
        font-weight: normal;
    }

}

.header {
    position: relative;

    .header-container {
        @include media-breakpoint-up(lg) {
            max-width: 1250px !important;
        }
    }

    .bootstrap-select {
        .dropdown-toggle {
            box-shadow: none;
            background-color: $navbar-bg-color;
            border-color: $navbar-bg-color;

            &:active, &:hover {
                background-color: $navbar-bg-color;
                border-color: $navbar-bg-color;
            }

            &:focus {
                border: none;
                outline: none !important;
            }
        }
    }

    &__contact-link {
        color: $navbar-link-color;

        i {
            color: $primary-color;
        }

        &:hover {
            text-decoration: none;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 6.25rem;
        }
    }

    .subnav {
        display: none;
        position: absolute;
        padding: 0.9375rem;
        top: 100%;
        z-index: 10;
        background: rgba(55, 77, 160, 0.5);
        width: 100%;

        ul {
            columns: 2;
            list-style-type: none;
            color: white;
            text-transform: uppercase;
            font-weight: bold;

            li a {
                text-decoration: none;
                color: white;
            }
        }
    }
}

.news-slider {
    .slick-slide {

        width: calc(100vw / 1.4);
        margin: 0 1.6875rem;
        @include media-breakpoint-up(lg){
            width: calc(100vw / 2.8);
        }
    }

    .slide-item {
        border: 0.125rem solid $primary-color;
        margin-bottom: 0.9375rem;
        margin-right: 0.9375rem;
        position: relative;

        img {
            width: 100%;
        }

        &__date {
            background-color: $primary-color;
            position: absolute;
            top: 0;
            padding: 0.625rem;
        }
    }

    .slick-dots {
        a {
            font-size: 20px;
            color: $primary-color;
        }

        .slick-active {
            a {
                color: $slider-link-color;
            }

        }
    }

}

.carousel {
    max-height: 22.75rem;
    height: 22.75rem;
    @include media-breakpoint-up(lg){
        max-height: 32.75rem;
        height: 32.75rem;
    }
   @include media-breakpoint-up(xl){
       max-height: 43.75rem;
       height: 43.75rem;
   }

    .carousel-inner {
        height: 100%;
    }

    .carousel-item {
        height: 100%;
    }
    .carousel-img {
        background-repeat: no-repeat;
        background-size: cover;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .carousel-caption {
        background-color: black;
        opacity: 0.5;
        top: 5vw;
        bottom: unset;
        border-radius: 0.625rem;
        width: 40%;
        text-align: left;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        @include media-breakpoint-up(xl) {
            top: 10vw;
        }

        .read-more {
            color: white;
            font-size: 2.8125rem;
        }

        h5 {
            font-size: 2.25rem;
        }

        p {
            font-size: 1.5rem;
        }
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        background-image: unset;
    }

    .carousel-control-prev-icon:after, .carousel-control-next-icon:after {
        font-size: 2.8125rem;
    }
}


.content-padding {
    h2,h3,p,button {
        padding: 0 30px;
        @include media-breakpoint-up(lg){
            padding: 0 200px;
        }

    }
table{
    margin: 0 30px;
    @include media-breakpoint-up(lg){
        margin: 0 200px;
    }
    img{
        width: 100%;
    }
}
    ul {
        font-size: 20px;
        padding: 0 50px;
        margin-left: 30px;
        @include media-breakpoint-up(lg){
            padding: 0 200px;
        }

    }
}

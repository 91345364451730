// Body
$body-bg: #fff;


$primary-color: #374DA0;
$secondary-color: #F4F4F4;
$secondary-bg: $secondary-color;
$title-color: #001e3c;

$navbar-link-color: #012237;
$navbar-link-active-color: #FCB16D;
$navbar-bg-color: $body-bg;

$button-bg-color: $primary-color;
$button-color: #fff;

$read-more-color: #001e3c;
$read-more-secondary-color: #FCB16D;

$news-bg-color: $secondary-color;
$news-color: #001e3c;
$news-date-color: #FCB16D;

$parameters-bg-color: $primary-color;
$parameters-color: #fff;

$footer-bg-color: $primary-color;
$footer-color: #fff;
$footer-privacy-link-bg-color: #33437F;

$slider-link-color: #FCB16D;

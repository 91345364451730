.cover {
    position: relative;
    &__text {
        background-color: rgba(0,0,0,0.5);
        position: absolute;
        top: 10vw;
        bottom: unset;
        border-radius: 0.625rem;
        min-width: 40%;
        padding: 1.5rem;
        left: 15%;
        z-index: 10;
        color: #fff;
        min-height: 10vw;
        @include media-breakpoint-up(xl){
            width: 40%;
            min-width: unset;
        }
    }
    &--investment{
        .cover__text {
            top: 5vw;

            @include media-breakpoint-up(xl){
                width: 70%;
            }
            @media screen and (min-width: 1440px) {
                width: 55%;
                top: 10vw;
            }
        }
    }
}

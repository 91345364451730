// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~mdb-ui-kit/css/mdb.min.css';
@import '~lightslider/src/css/lightslider.css';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';


@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "~bootstrap-select/sass/bootstrap-select";

@import "~flexslider/flexslider.css";

@import "base/fonts";
@import "base/base";
@import "components/header";
@import "components/navbar";
@import "components/footer";
@import "components/button";
@import "components/read-more";
@import "components/text-left-img-right";
@import "components/solar-parks";
@import "components/solar-icons";
@import "components/investors";
@import "components/news";
@import "components/carousel";
@import "components/cover";
@import "components/slider";
@import "components/content";
@import "components/parameters";
@import "components/workers";
@import "components/news-slider";
@import "components/downloads";
@import "components/content-padding";
@import "components/error-page";
@import "components/flexslider";


.flexslider {
    border: none;
    margin: 0;
    max-height: 22.75rem;
    height: 22.75rem;
    @include media-breakpoint-up(lg) {
        max-height: 32.75rem;
        height: 32.75rem;
    }
    @include media-breakpoint-up(xl) {
        max-height: 43.75rem;
        height: 43.75rem;
    }

    li {
        max-height: 22.75rem;
        height: 22.75rem;
        background-size: cover;
        @include media-breakpoint-up(lg) {
            max-height: 32.75rem;
            height: 32.75rem;
        }
        @include media-breakpoint-up(xl) {
            max-height: 43.75rem;
            height: 43.75rem;
        }
    }

    .flex-direction-nav a {
        height: 3.125rem;

        &:before {
            color: white;
        }
    }
    .flex-caption {
        background-color: rgba(0,0,0,0.3);
        color:white;
        position: absolute;
        top: 5vw;
        left: 15vw;
        bottom: unset;
        border-radius: 0.625rem;
        width: 40%;
        text-align: left;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        @include media-breakpoint-up(xl) {
            top: 10vw;
        }

        .read-more {
            color: white;
            font-size: 2.8125rem;
        }

        h5 {
            font-size: 2.25rem;
        }

        p {
            font-size: 1.5rem;
        }
    }
}

.flexslider .flex-direction-nav .flex-next {
    right: 0.3125rem; /* adjust offset to match the hover style */
    opacity: .8; /* adjust opacity to match the hover style */
}

.flexslider .flex-direction-nav .flex-prev {
    left: 0.3125rem; /* adjust offset to match the hover style */
    opacity: .8; /* adjust opacity to match the hover style */
}

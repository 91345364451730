.news {
    //  background-color: $news-bg-color;

    .column {
        padding: 1rem;
        flex: 1 1 100%;
        @include media-breakpoint-up(lg) {
            flex: 1 1 50%;
            padding: 1rem 3rem;
            display: flex;
            flex-direction: column;
        }
    }

    .news-inner {
        &:first-of-type {
            background-color: $news-bg-color;
        }

        &:last-of-type {
            background-color: white;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }

    &__box {
        padding: 1rem 3rem;
        height: 100%;

        &--colored {
            background-color: $news-bg-color;
        }
    }

    &-item {
        font-size: 1.25rem;
        color: $news-color;
        margin-bottom: 1rem;
        padding: 0.5rem;
      //  min-height: 170px;
        @include media-breakpoint-up(md) {
            padding: 1rem;
        }

        &--highlighted {
            background: rgb(244,244,244);
            background: linear-gradient(0deg, rgba(244,244,244,1) 0%, rgba(247,216,189,1) 41%, rgba(251,170,97,1) 78%, rgba(253,147,53,1) 100%);
            border-radius: 0.5rem;
        }

        &--colored {
            background: rgb(114,130,186);
            background: linear-gradient(0deg, rgba(114,130,186,1) 23%, rgba(72,92,167,1) 59%, rgba(55,77,160,1) 100%);
            color: white;
            border-radius: 0.5rem;

        }

        &__title {
            color: #001e3c;

            &:hover {
                text-decoration: none;
                color: #001e3c;
            }
        }

        & > div.list-img {
            flex-basis: 100px;
            flex-shrink: 0;
            @include media-breakpoint-up(md) {
                flex-basis: 159px;

                flex-shrink: 0;
            }

        }

        & > div.news-list-item-content {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;

        }

    }

}

.slider {
    #light-slider {
        height: 350px !important;
    }

    .lSPager {
        display: none;
    }

    .lSAction {
        display: block !important;

        .lSPrev {
            left: 0;
            background-image: url("../assets/img/left.png");
        }

        .lSNext {
            right: 0;
            background-image: url("../assets/img/right.png");
        }

        a {
            background-color: #eaa207;
            height: 73px;
            top: 42%;
            opacity: unset;
            background-size: 25px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .lslide {
        margin-right: 0 !important;
        position: relative;
        height: 100%;
       /* width: 33.333333% !important;*/

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 5;
        }
    }

    .slider-item {
        position: relative;
        height: 100%;

        &__content {
            position: absolute;
            z-index: 10;
            top: 25%;
            left: 10%;
            color: white;
            width: 75%;

            .read-more {
                color: white;
                font-size: 35px;
            }
        }
    }
}

.text-left-img-right {

    img {
        width: 100%;
        @include media-breakpoint-up(xl) {
            width: unset;
        }
    }

    &__text-box {
        width: 100%;
        position: unset;
        margin-bottom: 1rem;
        margin-top: 1.9375rem;

        @include media-breakpoint-up(md) {
            padding-left: 5.9375rem;
            margin-top: 4.9375rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.25rem;
            width: 31vw;
            margin-left: 7.375rem;
            margin-top: 2.9375rem;
            padding-left: unset;
            p,b,a{
                font-size: 1.25rem;
            };
        }
        @media screen and (min-width: 1270px){
            width: 37.5vw;
            left: 15.375rem;
        }
        @media screen and (min-width: 1750px){
            width: 57%;
            left: 15.375rem;
        }

    }

    &--colored {
        background-color: $secondary-bg;
    }
}

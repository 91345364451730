.solar-icons {
    @include media-breakpoint-up(lg) {
        background-image: url("../assets/img/icons-bg.png");
        background-size: cover;
    }

    &__item {
        text-align: center;
        margin-bottom: 0.5rem;
    }

    &__icon {
        margin-bottom: 1rem;
        height: 115px;
        width: auto;
    }

    &__title {
        color: $title-color;
        font-size: 18px;
        font-weight: 900;
    }

    &__label {
        color: $title-color;
        font-size: 18px;
    }
}

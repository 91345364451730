@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Semibold.eot');
    src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
    url('../fonts/MyriadPro-Semibold.woff') format('woff'),
    url('../fonts/MyriadPro-Semibold.ttf') format('truetype'),
    url('../fonts/MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-BoldCond.eot');
    src: url('../fonts/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-BoldCond.woff2') format('woff2'),
    url('../fonts/MyriadPro-BoldCond.woff') format('woff'),
    url('../fonts/MyriadPro-BoldCond.ttf') format('truetype'),
    url('../fonts/MyriadPro-BoldCond.svg#MyriadPro-BoldCond') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Cond.eot');
    src: url('../fonts/MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-Cond.woff2') format('woff2'),
    url('../fonts/MyriadPro-Cond.woff') format('woff'),
    url('../fonts/MyriadPro-Cond.ttf') format('truetype'),
    url('../fonts/MyriadPro-Cond.svg#MyriadPro-Cond') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-BoldIt.eot');
    src: url('../fonts/MyriadPro-BoldIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-BoldIt.woff2') format('woff2'),
    url('../fonts/MyriadPro-BoldIt.woff') format('woff'),
    url('../fonts/MyriadPro-BoldIt.ttf') format('truetype'),
    url('../fonts/MyriadPro-BoldIt.svg#MyriadPro-BoldIt') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-BoldCondIt.eot');
    src: url('../fonts/MyriadPro-BoldCondIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-BoldCondIt.woff2') format('woff2'),
    url('../fonts/MyriadPro-BoldCondIt.woff') format('woff'),
    url('../fonts/MyriadPro-BoldCondIt.ttf') format('truetype'),
    url('../fonts/MyriadPro-BoldCondIt.svg#MyriadPro-BoldCondIt') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-CondIt.eot');
    src: url('../fonts/MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-CondIt.woff2') format('woff2'),
    url('../fonts/MyriadPro-CondIt.woff') format('woff'),
    url('../fonts/MyriadPro-CondIt.ttf') format('truetype'),
    url('../fonts/MyriadPro-CondIt.svg#MyriadPro-CondIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Bold.eot');
    src: url('../fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-Bold.woff2') format('woff2'),
    url('../fonts/MyriadPro-Bold.woff') format('woff'),
    url('../fonts/MyriadPro-Bold.ttf') format('truetype'),
    url('../fonts/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Light.eot');
    src: url('../fonts/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-Light.woff2') format('woff2'),
    url('../fonts/MyriadPro-Light.woff') format('woff'),
    url('../fonts/MyriadPro-Light.ttf') format('truetype'),
    url('../fonts/MyriadPro-Light.svg#MyriadPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-Regular.eot');
    src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('../fonts/MyriadPro-Regular.woff') format('woff'),
    url('../fonts/MyriadPro-Regular.ttf') format('truetype'),
    url('../fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/MyriadPro-SemiboldIt.eot');
    src: url('../fonts/MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-SemiboldIt.woff2') format('woff2'),
    url('../fonts/MyriadPro-SemiboldIt.woff') format('woff'),
    url('../fonts/MyriadPro-SemiboldIt.ttf') format('truetype'),
    url('../fonts/MyriadPro-SemiboldIt.svg#MyriadPro-SemiboldIt') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}


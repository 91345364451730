.read-more{
    float:right;
    color: $read-more-color;
    font-weight: bold;
    &:hover{
        text-decoration: none;
    }
    &--secondary{
        color:  $read-more-secondary-color;
        &:hover{
            color:  $read-more-secondary-color;
        }
    }
    &.news-readmore{
        position: absolute;
        bottom:0.5rem;
        right: 0.5rem;
    }
}

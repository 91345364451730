body {
    font-family: "Myriad Pro";
    color: #001e3c;
}

h1 {
    font-weight: 900;
}

h2 {
    font-size: 40px;
    color: $title-color;
    font-weight: 900;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}

h4 {
    font-size: 1.625rem;
    color: $title-color;
    font-weight: 900;
}

.show > .btn-light.dropdown-toggle {
    border-color: white !important;
}

p {
    font-size: 20px;
}
.page{
    ul, li {
        font-size: 20px;
    }
}


.ytp-pause-overlay-controls-hidden .ytp-pause-overlay {
    display: none;
}

.solar {
    padding: 100px 50px;
    position: relative;
    height: 350px;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 5;
    }

    .solar-item {
        position: relative;
        height: 100%;

        &__content {
            position: absolute;
            z-index: 10;
            top: -15%;
            left: 10%;
            color: white;
            width: 75%;
            @include media-breakpoint-up(md) {
                top: 25%;
            }

            .read-more {
                color: white;
                font-size: 35px;
            }
        }
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.border-none {
    border: none !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.float-unset {
    float: unset !important;
}

.bg-gray {
    background-color: $secondary-bg;
}

.text-orange {
    color: #FCB16D !important;
}

.text-white {
    color: white !important;
}

.text-blue {
    color: #31507f;
}

.text-darkblue {
    color: #0C2556;
}
